export const notification = {
    addedTalentAndCreatedProject: 'Project was created and talent was added.',
    addedTalentToProject: 'Talent was added to the project.',
    addedTalentsAndCreatedProject:
        'Project was created and talents were added.',
    addedTalentsToProject: 'Talents were added to the project.',
    addingTalentWatchlistFailed:
        'Talent could not be added to the project. Please try again.',
    addingTalentsWatchlistFailed:
        'Talents could not be added to the project. Please try again.',
    attachementsFailed:
        'Unfortunately, we are not able to load the attachments at the moment. ' +
        'We are already taking care of it.',
    conversationSendRequests: 'Your requests will be sent!',
    conversationSendRequestsFailed:
        'No request could be sent to the following talents $failedTalentIds!',
    conversationSendRequestsInfo:
        'We will notify you as soon as an enquiry is answered.',
    createTemplateFailed:
        'The template could not be created. Please try again.',
    deleteMessage: 'The selected message will be permanently moved to the bin.',
    deleteMessageFailed: 'Message could not be deleted. Please try again.',
    deleteMessageTitle: 'Permanently delete message',
    deleteMessages:
        'The selected messages will be permanently moved to the bin.',
    deleteMessagesTitle: 'Permanently delete messages',
    deleteNoteFailed: 'Note could not be deleted. Please try again.',
    deleteProjectFailed: 'Project could not be deleted. Please try again.',
    deleteSavedSearchFailed:
        'Saved Search could not be deleted. Please try again.',
    deleteTemplateFailed: 'Template could not be deleted. Please try again.',
    deleteTemplateTitle: 'The template will be permanently deleted.',
    deleteTemplatesFailed: 'Templates could not be deleted. Please try again.',
    deleteTemplatesTitle: 'The templates will be permanently deleted.',
    deletedNote: 'Note deleted.',
    deletedTalentMessages:
        'Unfortunately, messages from deleted talents cannot be displayed.',
    downloadFailed: 'Download failed, please try again',
    editNoteFailed: 'Note could not be edited. Please try again.',
    editProjectFailed: 'Project could not be edited. Please try again.',
    editTemplateFailed: 'Template could not be edited. Please try again.',
    editedNote: 'Note edited.',
    failedSendingMessageLabel: 'Sorry, something went wrong!',
    linkCopied: 'Link copied.',
    loadingMoreFilesFailed:
        'Unfortunately, we could not load $numberOfFiles files. ' +
        'We are already working on a solution.',
    loadingOneFileFailed:
        'Unfortunately, we could not load a file. We are already working on a solution.',
    loadingProjectsFailed:
        'Project list could not be loaded, please reload the page.',
    markConversationAsReadFailed:
        'The message could not be marked as read. Please try again.',
    markConversationsAsReadFailed:
        'The messages could not be marked as read. Please try again.',
    matchingBuyJobAdInfo:
        'You can buy new adverts in the online shop, or activate one of your existing adverts to enable matching',
    matchingNoMatchingAvailable: 'No Matching available',
    matchingNoMatchingFound:
        'Based on your job advertisements, current profiles of qualified specialists from our talent database are displayed.',
    maxNumberOfProjectsReached: 'Maximum number of projects reached.',
    noAttachment: 'There is no attachment',
    noMatchingJobAd:
        'There is no advertisement that matches your search query.',
    noResultTalentDetailPage: 'No talent was found.',
    noResultViewContactList: 'No contact request was found.',
    noResultViewHeader: 'We could not find',
    noResultViewHeaderCandidate: 'any talents',
    noResultViewHeaderSearchResult: 'for your search query.',
    noResultViewHintFilter: 'Use fewer filters',
    noResultViewHintProfile:
        'The talent profile with the searched profile ID is no longer visible',
    noResultViewHintSearchResult: 'Use more generic search terms',
    noResultViewHintSemantic: 'Check the notation',
    noResultViewListHeader: 'The following tips might be helpful for you:',
    notesAndRatingsWillBeDeleted:
        'Ratings of individual talents are permanently deleted.',
    profileNotFound: 'Profile was not found',
    profileNotFoundOrDeleted:
        'This profile does not exist or has been deleted by the user.',
    projectCreated: 'Project created.',
    projectNotExisting: 'The project you want to open does not exist.',
    removeTalentFromWatchlistFailed:
        'The talent could not be removed from the project. Please try again.',
    savedNote: 'Saved note.',
    savingNoteFailed: 'Note could not be created. Please try again.',
    savingProjectFailed: 'Project could not be created. Please try again.',
    savingRatingFailed: 'The rating could not be saved. Please try again.',
    savingSearchError: 'Search could not be saved.',
    savingSearchSuccessful: 'Search saved successfully.',
    sendRequest: 'One message sent!',
    sendRequests: '$length messages sent!',
    shareNoteIsNotPossible:
        'A release is no longer possible because a released recruiter does not have an active talent.cloud licence.',
    somethingWentWrong:
        'Something went wrong. We are already working on a solution.',
    successfulSendingMessageLabel: 'Your message has been sent!',
    successfulSendingMessageSublabel:
        'We will notify you as soon as the request is answered.',
    successfulSendingRequestLabel: 'Your request has been sent!',
    sureToDeleteProject: 'Are you sure you want to delete the project?',
    sureToRemoveTalentFromWatchlist:
        'Are you sure you want to remove this talent from the project?',
    talentWasAlreadySaved: 'Talent is already saved in the project.',
    templateDataLoadingFailed:
        'The selected template could not be loaded. Please try again.',
    templateListLoadingFailed: 'The templates could not be loaded.',
    templateSavingFailed: 'Template could not be saved. Please try again.',
    templateWasSaved: 'Template was saved',
    tourLoadingError:
        'The tour could not be started. Please reload the page to try again.',
};
