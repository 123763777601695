export const table = {
    lastPosition: 'Last position',
    page: 'Page',
    pageSize: 'Page size',
    position: 'Position',
    profileId: 'Profile-ID',
    totalPages: 'Total pages',
    totalResults: 'TotalResults',
    wishLocation: 'Wish location',
};
