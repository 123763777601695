export const table = {
    lastPosition: 'Letzte Anstellung',
    page: 'Seite',
    pageSize: 'Seitengröße',
    position: 'Position',
    profileId: 'Profil-ID',
    totalPages: 'Insgesamte Seiten',
    totalResults: 'Insgesamte Treffer',
    wishLocation: 'Wunschort',
};
