export const tooltips = {
    activeOnJobSearch:
        'This talent is more active on the karriere.at \n  platform than 50% of the visible talents.',
    applicantDeleted: 'This profile has been deleted.',
    applicantInactive:
        'This profile is currently deactivated. The talent can change the visibility at any time.',
    approvalStatusAccepted: 'The talent has accepted your request.',
    approvalStatusPending: 'The talent has not yet responded to your request.',
    approvalStatusUnapproved:
        'The talent does not have any contact with you yet.',
    availableWithValidLicense:
        'This functionality is available with an active license.',
    deletedTalent:
        'The talent has deleted their profile and can no longer be contacted.',
    inactiveTalent:
        'The talent has changed its visibility settings and can no longer be contacted.',
    lastContacted: 'Last contacted on:',
    lastContactedFrom: 'Last contacted on $lastMessageDate by $contactedBy',
    lastVisitedOn: 'Last visited on $visitedOn',
    projectAdmin:
        'Only a project administrator can permanently delete a project.',
    saveAsTemplate: 'Save as template',
};
