export const input = {
    addAttachment: 'Add attachment',
    contactListFilterPlaceholder: 'talent name, job title, …',
    contactRequest: 'Contact request',
    educationFilter: 'Specify education',
    email: 'E-mail address',
    employmentFilter: 'Specify employment type',
    enterEmail: 'Enter e-mail address',
    enterProjectName: 'Enter project name',
    enterSubject: 'Enter subject',
    firstName: 'First name',
    initialSearchPlaceholder: 'find the best occupation',
    jobFieldFilter: 'Specify job field',
    keywordFilter: 'Specify CV part',
    keywordPlaceholder: 'Job title, profile id, “exact wording”, …',
    languageFilter: 'Specify language',
    lastName: 'Last name',
    levelFilter: 'all',
    locationPlaceholder: 'Location, Region',
    matchingSearchPlaceholder: 'Search ads',
    maximum: 'maximum $amount',
    messageBody: 'Your message',
    messageBodyOptional: 'Your message (optional)',
    moveMessage: 'Message has been moved to the bin.',
    moveMessages: 'Messages have been moved to the bin.',
    notSpecified: 'Not specified',
    phoneNumber: 'Phone number',
    pleaseSelect: 'Please select...',
    requestMessage:
        'Good day!\n\nWe are currently looking for [position title] ' +
        'and your profile on has caught our interest! We would be happy to get to ' +
        'know you better and would like to contact you directly. If you unlock your ' +
        'contact details for us, we will get back to you personally shortly with more information!\n\nKind regards,',
    salaryTo: 'e.g.: 3500',
    salutation: 'Salutation',
    searchIn: 'Search in',
    searchName: 'Enter the name of the search',
    selectStaffingNeeds: 'Select staffing needs',
    show: 'Show: ',
    subject: 'Subject',
    templateBody: 'Content of the template',
    templateName: 'Name of the template',
    templateNameExample: 'e.g. "Cover letter for accounting specialist"',
    templateSelect: 'Select template',
    templateSelectEmpty: 'No templates available',
    templateSubject: 'Subject of the template',
    titleOptional: 'Title (optional)',
    writeMessage: 'Write message…',
};
