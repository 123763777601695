export const label = {
    aboutMe: 'About me',
    active: 'Active',
    activeSourcing: 'Talent Search',
    activeTalent: 'Active talent',
    actuality: 'Actuality',
    addTalentToProject: 'Add talent to project',
    addToProject: 'Add to project',
    age: 'Age',
    allInCompany: 'All in company',
    allowedFileType: 'JPG, PNG, PDF, DOC, DOCX, ODT',
    allowedMaxFileSize: ' - max 3MB',
    alphabetical: 'Alphabetical',
    any: 'Any',
    applicant: 'Applicant',
    approvalMessageHeader: 'Request for contact information release',
    approvalMessageSubHeader:
        'A personal inquiry helps the talent to decide to accept your request.',
    approved: 'Approved',
    ascending: 'ascending',
    attachment: 'Attachment',
    availability: 'Availability',
    bin: 'Bin',
    candidatesFound: 'Talents found',
    checkYourInternetConnection: 'Check your internet connection',
    chooseColor: 'Select color',
    chooseProject: 'Choose project',
    clv: 'Active talents first',
    contactApproval: 'Contact approval',
    contactApprovals: 'Contact approvals',
    contactDateText: 'Your contact request from ',
    createProject: 'Create new project',
    creationDate: 'Creation Date',
    date: 'Date',
    day: 'Day',
    days: 'Days',
    daysAgoActive: 'active %s days ago',
    daysLC: 'days',
    delete: 'Delete',
    deleteFromProject: 'Remove from project',
    deleteNote: 'Delete note',
    deleteProject: 'Delete project',
    deleteSavedSearchText: 'Your saved search will be deleted.',
    deleteSavedSearchTitle: 'Delete saved search',
    deletedProfile: 'Deleted profile',
    descending: 'descending',
    description: 'Description',
    deselectTalentsOrBuyPackage:
        'Select talents or buy a contact package to be able to contact all talents.',
    desiredJob: 'Desired Job',
    desiredSalary: 'Desired salary',
    diverse: 'Diverse',
    dragIntoOr: 'drag into or ',
    editNote: 'Edit note',
    education: 'Education',
    educationGroups: 'Education types',
    email: 'E-Mail',
    employer: 'Employer',
    employmentTypes: 'Employment',
    enterNote: 'Enter note',
    exactWording: 'Exact wording',
    experience: 'Experience',
    experiencesInJobField: 'Work experience in the job field',
    fields: 'fields',
    files: 'Files',
    filterKeyword: 'Keyword filters',
    filterTitle: 'Filter results',
    filters: 'Filters',
    furtherEducation: 'Further education',
    goToItemX: 'Go to element $elementIndex',
    homeoffice: 'Homeoffice (days per week)',
    homeofficeTitle: 'Homeoffice',
    inCompany: 'in the company',
    inactiveProfile: 'Inactive profile',
    interests: 'Interests',
    jobExperience: 'Professional experience',
    jobField: 'Job field',
    jobId: 'Job-ID',
    jobLevel: 'Job level',
    keyDate: 'Key data',
    keyword: 'Keyword',
    language: 'Language',
    languages: 'Languages',
    lastModified: 'Last modified',
    lastModifiedOn: 'Last modified',
    lastOnline: 'Last online',
    lastProfileUpdate: 'Last profile update',
    latestNews: 'Latest News',
    level: 'Level',
    location: 'Location',
    manualInput: 'Manual input',
    markAsRead: 'Mark as read',
    maximal: 'Maximal',
    message: 'Message',
    messages: 'Messages',
    minimal: 'Minimal',
    month: 'Month',
    monthLC: 'month',
    months: 'Months',
    monthsLC: 'months',
    name: 'Name',
    new: 'NEW',
    newNote: 'New note',
    newTalent: 'NEW TALENT',
    newTalents: 'NEW TALENTS',
    next: 'Next',
    noConversations: 'No messages available.',
    noConversationsInBin: 'You have no messages in the bin.',
    noFreeContactRequest: 'no free contact requests',
    noNotesAvailable: 'No notes available',
    noSelection: '---',
    noTalentsInProject:
        'You do not have any talents saved in this project yet.',
    noTalentsInSavedSearch: 'You have not saved a search yet.',
    notes: 'Notes',
    objective: 'Objective',
    objectiveJob: 'Desired Job',
    objectiveSalary: 'Desired Salary',
    of: 'of',
    oneFreeContactRequest: 'one free contact request',
    online: 'online',
    only: 'only',
    page: 'Page',
    pageSize: 'Page size',
    personality: 'Personality',
    personalityTraits: 'Personality traits',
    previous: 'Previous',
    projectAdmin: 'Project administrator',
    projectName: 'Project name',
    projectsAndAwards: 'Projects & Awards',
    rating: 'Rating',
    read: 'Read',
    readyToMove: 'ready to move',
    readyToMoveUC: 'Show talents ready to move',
    refineSearch: 'Refine search',
    releaseNotesHeading:
        'Here is an overview of changes, improvements and fixes.',
    relevance: 'Relevance',
    removeTalent: 'Remove talent',
    removeTalentFromWatchlist: 'Remove talent from project',
    requestProduct: 'Product request talent.cloud',
    resume: 'CV',
    salary: 'Salary',
    salaryFrom: 'Salary from',
    salaryRange: 'Salary range',
    salaryRangeGrossAndNet: 'Salary range (gross/month)',
    salaryTo: 'Salary to',
    salutationMr: 'Mr',
    salutationMs: 'Ms',
    saveNewTemplate: 'Save new template',
    saveRating: 'Save rating',
    saveSearch: 'Save search',
    saveSearchParameters: 'This search with all its parameters will be saved.',
    search: 'Search',
    searchResult: 'Search result',
    searchTips: 'Tips for searching with boolean operators',
    selectAll: 'Select all',
    selectContact: 'Select contact',
    send: 'Send',
    sendRequest: 'Send request',
    settings: 'Settings',
    shareNote: 'Share note',
    shareTalent: 'Share talent',
    showAll: 'Show all',
    showAllTalents: 'Show all talents',
    showMore: 'more',
    since: 'since',
    skills: 'Skills',
    sort: 'Sort',
    staffingDemandBetween10And50: '10 - 50 positions',
    staffingDemandLower10: '< 10 positions',
    staffingDemandOver50: '> 50 positions',
    staffingNeeds: 'Your annual staffing needs (optional)',
    subject: 'Subject',
    sureAboutDeletingNote:
        'Are you sure you want to delete the note permanently?',
    talent: 'Talent',
    talents: 'Talents',
    template: 'Template',
    templates: 'Templates',
    timeAgoOnline: 'online $timeNumber $timeUnit ago',
    title: 'Title',
    today: 'today',
    tryReload: 'Try to reload the page',
    unanswered: 'Unanswered',
    unread: 'Unread',
    until: 'until',
    week: 'week',
    weeks: 'weeks',
    willingnessToTravel: 'Willingness to travel (days per week)',
    willingnessToTravelTitle: 'Willingness to travel',
    withinLastMonth: 'Within the last month',
    withinLastXDays: 'Within the last $days days',
    withinLastXMonths: 'Within the last $months months',
    xFreeContactRequests: '$contingent free contact requests',
    year: 'Year',
    yearLC: 'year',
    years: 'Years',
    yearsLC: 'years',
    yesterday: 'yesterday',
    youCanDoTheFollowing: 'You can try the following:',
    youHave: 'You have',
};
