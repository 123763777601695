import { button as buttonDe } from '../lang/de/button';
import { contract as contractDe } from '../lang/de/contract';
import { exception as exceptionDe } from '../lang/de/exception';
import { header as headerDe } from '../lang/de/header';
import { input as inputDe } from '../lang/de/input';
import { label as labelDe } from '../lang/de/label';
import { notification as notificationDe } from '../lang/de/notification';
import { page as pageDe } from '../lang/de/page';
import { prompt as promptDe } from '../lang/de/prompt';
import { searchTips as searchTipsDe } from '../lang/de/searchTips';
import { table as tableDe } from '../lang/de/table';
import { tooltips as tooltipDe } from '../lang/de/tooltips';
import { validation as validationDe } from '../lang/de/validation';
import { button as buttonEn } from '../lang/en/button';
import { contract as contractEn } from '../lang/en/contract';
import { exception as exceptionEn } from '../lang/en/exception';
import { header as headerEn } from '../lang/en/header';
import { input as inputEn } from '../lang/en/input';
import { label as labelEn } from '../lang/en/label';
import { notification as notificationEn } from '../lang/en/notification';
import { page as pageEn } from '../lang/en/page';
import { prompt as promptEn } from '../lang/en/prompt';
import { searchTips as searchTipsEn } from '../lang/en/searchTips';
import { table as tableEn } from '../lang/en/table';
import { tooltips as tooltipEn } from '../lang/en/tooltips';
import { validation as validationEn } from '../lang/en/validation';

const translationMap: Record<string, Record<string, Record<string, string>>> = {
    de: {
        button: { ...buttonDe },
        contract: { ...contractDe },
        exception: { ...exceptionDe },
        header: { ...headerDe },
        input: { ...inputDe },
        label: { ...labelDe },
        notification: { ...notificationDe },
        page: { ...pageDe },
        prompt: { ...promptDe },
        searchTips: { ...searchTipsDe },
        table: { ...tableDe },
        tooltips: { ...tooltipDe },
        validation: { ...validationDe },
    },
    en: {
        button: { ...buttonEn },
        contract: { ...contractEn },
        exception: { ...exceptionEn },
        header: { ...headerEn },
        input: { ...inputEn },
        label: { ...labelEn },
        notification: { ...notificationEn },
        page: { ...pageEn },
        prompt: { ...promptEn },
        searchTips: { ...searchTipsEn },
        table: { ...tableEn },
        tooltips: { ...tooltipEn },
        validation: { ...validationEn },
    },
};

const lang = ref('de');

const availableLanguages = [
    {
        id: 1,
        label: 'DE',
    },
    {
        id: 2,
        label: 'EN',
    },
];

export const useLang = () => {
    const setLang = (newLang: string) => {
        window?.localStorage?.setItem('locale', newLang);
        lang.value = newLang;
    };

    const t = (group: string, target: string, options = {}) =>
        unref(
            computed(() => {
                let string = translationMap[lang.value][group][target];

                for (const [key, value] of Object.entries(options)) {
                    string = string.replaceAll(`$${key}`, value as string);
                }
                return string;
            }),
        );

    return {
        availableLanguages,
        lang,
        setLang,
        t,
    };
};
