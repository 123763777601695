export const page = {
    infoPage: 'Information',
    matching: 'Matching',
    messages: 'Nachrichten',
    platform: 'karriere.at Business Portal',
    projects: 'Projekte',
    releases: 'Versionshinweise',
    savedSearch: 'Meine Suchen',
    search: 'Talentsuche',
};
