export const validation = {
    emailInvalid: 'The E-mail address is invalid',
    emptyStateContactTemplateView:
        'Select a template on the left to see details.',
    emptyStateContactView: 'Select a message on the left to see details.',
    emptyStateProfileView: 'Select a profile on the left to see details.',
    emptyStateTemplateList: 'You have not yet created any templates.',
    enterProjectName: 'Please enter a project name.',
    enterSearchName: 'Please enter a name for this search.',
    hint: 'Hint',
    invalidCharacterCombination: 'Character combination is invalid.',
    maxIsGreaterThanMin: 'Max value should be bigger than min value.',
    maxLengthCharacters: 'No more than $length characters are allowed.',
    maxProfileShareEmails: 'A maximum of 5 e-mails can be sent.',
    maxUploadedFiles: 'Maximum 5 files can be uploaded.',
    messageHasAttachmentError: 'One or more attachments could not be loaded.',
    minJobFieldSelection: 'Choose one or more job fields.',
    minJobFieldSelectionHint:
        'The desired work experience is sought in each chosen career field, ' +
        'but does not have to be in all of them at the same time.',
    missingQuote: 'A quotation mark was not closed.',
    notAllowedFileSize: 'The file must not be empty or larger than 3MB.',
    notAllowedFileType: 'This file type is not allowed.',
    notEmptyField: 'This field should not be empty.',
};
