export const button = {
    add: 'Hinzufügen',
    addAllToProject: 'Alle zu Projekt hinzufügen',
    addExperience: '+ Berufserfahrung hinzufügen',
    addLanguage: '+ Sprache hinzufügen',
    apply: 'Verwenden',
    back: 'Zurück',
    backToOverview: 'Zurück zur Übersicht',
    backToProjects: 'Zurück zu Projekte',
    backToSearch: 'Zurück zur Suche',
    buyContactPackage: 'Kontaktpaket kaufen',
    buyContactPackageNow: 'Jetzt Kontaktpaket kaufen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    contact: 'Kontaktieren',
    contactMultiTalents: '$length Talente kontaktieren',
    contactOneTalent: '1 Talent kontaktieren',
    contactTalents: 'Talente kontaktieren',
    copyLink: 'Link kopieren',
    createProject: 'Projekt erstellen',
    deleteMessage: 'Nachricht löschen',
    deleteMessages: 'Nachrichten löschen',
    deleteSavedSearch: 'Suche löschen',
    deleteTemplate: 'Vorlage löschen',
    deleteTemplates: 'Vorlagen löschen',
    downloadAllAttachments: 'Alle Anhänge herunterladen',
    edit: 'Bearbeiten',
    filterReset: 'Zurücksetzen',
    filterSearch: 'Filter anwenden',
    filterSearchShort: 'Anwenden',
    giveRating: 'Bewertung abgeben',
    hideDetails: 'Details ausblenden',
    less: 'weniger',
    linkHidden: 'Link verdeckt',
    login: 'Anmelden',
    logout: 'Abmelden',
    more: 'mehr',
    moreOptions: 'Weitere Optionen',
    newNote: 'Neue Notiz',
    newTemplate: 'Neue Vorlage',
    next: 'Weiter',
    printAsPdf: 'Profil als PDF drucken',
    save: 'Speichern',
    saveChanges: 'Änderungen speichern',
    saveNote: 'Notiz speichern',
    saveSearch: 'Suche speichern',
    saveSettings: 'Einstellungen speichern',
    saveTemplate: 'Vorlage speichern',
    search: 'Suchen',
    send: 'Senden',
    sendContactRequest: 'Anfrage senden',
    sendMessage: 'Nachricht senden',
    sendProductRequest: 'Produktanfrage senden',
    sendRequest: 'Anfrage senden',
    shareProfile: 'Profil teilen',
    show: 'Anzeigen',
    showAll: 'alles anzeigen',
    showDetails: 'Details anzeigen',
    showLess: 'weniger anzeigen',
    showMoreJobAds: 'Weitere Inserate anzeigen',
    showMoreMessages: 'Weitere Nachrichten anzeigen',
    showMoreNotes: 'Weitere Notizen anzeigen',
    showMoreSavedSearch: 'Weitere Suchen anzeigen',
    showMoreTalents: 'Weitere Talente anzeigen',
    showMoreTemplates: 'Weitere Vorlagen anzeigen',
    showProfile: 'Profil anzeigen',
    toJobAdOverview: 'Zur Inserateübersicht',
    toOnlineShop: 'Zum Onlineshop',
    toSearch: 'Zur Talentsuche',
    understood: 'Verstanden',
    upload: 'hochladen',
};
