export const validation = {
    emailInvalid: 'Die E-Mail Adresse ist ungültig',
    emptyStateContactTemplateView:
        'Wählen Sie links eine Vorlage aus, um die Details zu sehen.',
    emptyStateContactView:
        'Wählen Sie links eine Nachricht aus, um die Details zu sehen.',
    emptyStateProfileView:
        'Wählen Sie links ein Profil aus, um die Details zu sehen.',
    emptyStateTemplateList: 'Sie haben noch keine Vorlagen erstellt.',
    enterProjectName: 'Bitte geben Sie einen Projektnamen ein.',
    enterSearchName: 'Bitte geben Sie einen Name für die Suche ein.',
    hint: 'Hinweis',
    invalidCharacterCombination: 'Zeichenkombination ist ungültig.',
    maxIsGreaterThanMin:
        'Der Maximalwert muss größer als der Minimalwert sein.',
    maxLengthCharacters: 'Nicht mehr als $length Zeichen sind erlaubt.',
    maxProfileShareEmails: 'Es können maximal 5 E-Mails versendet werden.',
    maxUploadedFiles: 'Maximal 5 Dateien können hochgeladen werden.',
    messageHasAttachmentError:
        'Ein oder mehrere Anhänge konnten nicht geladen werden.',
    minJobFieldSelection: 'Wähle ein oder mehrere Berufsfelder aus.',
    minJobFieldSelectionHint:
        'Die gewünschte Berufserfahrung wird in jedem gewählten Berufsfeld gesucht, ' +
        'muss aber nicht in allen gleichzeitig vorhanden sein.',
    missingQuote: 'Ein Anführungszeichen wurde nicht geschlossen.',
    notAllowedFileSize:
        'Die Datei darf nicht leer und nicht größer als 3 MB sein.',
    notAllowedFileType: 'Dieses Dateiformat ist nicht erlaubt.',
    notEmptyField: 'Das Feld darf nicht leer sein.',
};
