export const label = {
    aboutMe: 'Über mich',
    active: 'Aktiv',
    activeSourcing: 'Talentsuche',
    activeTalent: 'Aktives Talent',
    actuality: 'Aktualität',
    addTalentToProject: 'Talent zu Projekt hinzufügen',
    addToProject: 'Zu Projekt hinzufügen',
    age: 'Alter',
    allInCompany: 'Alle im Unternehmen',
    allowedFileType: 'JPG, PNG, PDF, DOC, DOCX, ODT',
    allowedMaxFileSize: ' - max. 3 MB',
    alphabetical: 'Alphabetisch',
    any: 'Beliebig',
    applicant: 'Bewerber',
    approvalMessageHeader: 'Anfrage zur Kontaktdatenfreigabe',
    approvalMessageSubHeader:
        'Eine persönliche Anfrage hilft der kontaktierten Person bei der Entscheidung,' +
        'die Anfrage anzunehmen.',
    approved: 'Angenommen',
    ascending: 'aufsteigend',
    attachment: 'Anhänge',
    availability: 'Verfügbarkeit',
    bin: 'Papierkorb',
    candidatesFound: 'Talente gefunden',
    checkYourInternetConnection: 'Überprüfen Sie Ihre Internetverbindung',
    chooseColor: 'Farbe auswählen',
    chooseProject: 'Projekt wählen',
    clv: 'Aktive Talente zuerst',
    contact: 'Kontaktieren',
    contactApproval: 'Kontaktfreigabe',
    contactApprovals: 'Kontaktfreigaben',
    contactDateText: 'Ihre Kontaktanfrage vom ',
    createProject: 'Neues Projekt erstellen',
    creationDate: 'Erstellungsdatum',
    date: 'Datum',
    day: 'Tag',
    days: 'Tage',
    daysAgoActive: 'vor %s Tagen aktiv',
    daysLC: 'Tagen',
    delete: 'Löschen',
    deleteFromProject: 'Aus Projekt entfernen',
    deleteNote: 'Notiz löschen',
    deleteProject: 'Projekt löschen',
    deleteSavedSearchText: 'Ihre Suche wird endgültig gelöscht.',
    deleteSavedSearchTitle: 'Gespeicherte Suche löschen',
    deletedProfile: 'Gelöschtes Profil',
    descending: 'absteigend',
    description: 'Beschreibung',
    deselectTalentsOrBuyPackage:
        'Wählen Sie Talente ab, oder kaufen Sie ein Kontaktpaket um alle Talente kontaktieren zu können.',
    desiredJob: 'Angaben zum Wunschjob',
    desiredSalary: 'Gehaltswunsch',
    diverse: 'Divers',
    dragIntoOr: 'reinziehen oder ',
    editNote: 'Notiz bearbeiten',
    education: 'Ausbildung',
    educationGroups: 'Ausbildingsarten',
    email: 'E-Mail',
    employer: 'Arbeitgeber',
    employmentTypes: 'Anstellungsart',
    enterNote: 'Notiz eingeben',
    exactWording: 'Exakter Wortlaut',
    experience: 'Berufserfahrung',
    experiencesInJobField: 'Erfahrung im Berufsfeld',
    fields: 'Bereiche',
    files: 'Dateien',
    filterKeyword: 'Keyword Filter',
    filterTitle: 'Ergebnisse filtern',
    filters: 'Filterung',
    furtherEducation: 'Weiterbildung',
    goToItemX: 'Gehe zu Element $elementIndex',
    homeoffice: 'Homeoffice (Tage pro Woche)',
    homeofficeTitle: 'Homeoffice',
    inCompany: 'im Unternehmen',
    inactiveProfile: 'Inaktives Profil',
    interests: 'Interessen',
    jobExperience: 'Berufserfahrung',
    jobField: 'Berufsfeld',
    jobId: 'Job-ID',
    jobLevel: 'Berufserfahrung',
    keyDate: 'Eckdaten',
    keyword: 'Beruf, Begriff',
    language: 'Sprache',
    languages: 'Sprachen',
    lastModified: 'Zuletzt geändert',
    lastModifiedOn: 'Zuletzt aktualisiert am',
    lastOnline: 'Zuletzt online',
    lastProfileUpdate: 'Letzte Profilaktualisierung',
    latestNews: 'Letzte Neuigkeiten',
    level: 'Level',
    location: 'Ort, Region',
    manualInput: 'Manuelle Eingabe',
    markAsRead: 'Als gelesen markieren',
    maximal: 'Maximal',
    message: 'Nachricht',
    messages: 'Nachrichten',
    minimal: 'Mindestens',
    month: 'Monat',
    monthLC: 'Monat',
    months: 'Monate',
    monthsLC: 'Monaten',
    name: 'Name',
    new: 'NEU',
    newNote: 'Neue Notiz',
    newTalent: 'NEUES TALENT',
    newTalents: 'NEUE TALENTE',
    next: 'Nächste',
    noConversations: 'Keine Nachrichten vorhanden.',
    noConversationsInBin: 'Sie haben keine Nachrichten im Papierkorb.',
    noFreeContactRequest: 'keine freien Kontaktanfragen',
    noNotesAvailable: 'Keine Notizen vorhanden',
    noSelection: '---',
    noTalentsInProject:
        'Sie haben noch keine Talente in diesem Projekt gespeichert.',
    noTalentsInSavedSearch: 'Sie haben noch keine Suche gespeichert.',
    notes: 'Notizen',
    objective: 'Wunschjob',
    objectiveJob: 'Wunschjob',
    objectiveSalary: 'Wunschgehalt',
    of: 'von',
    oneFreeContactRequest: '1 freie Kontaktanfrage',
    online: 'online',
    only: 'nur',
    page: 'Seite',
    pageSize: 'Seitengröße',
    personality: 'Persönlichkeit',
    personalityTraits: 'Persönlichkeitsmerkmale',
    previous: 'Vorherige',
    projectAdmin: 'Projekt-Administrator',
    projectName: 'Projektname',
    projectsAndAwards: 'Projekte & Auszeichnungen',
    rating: 'Bewertung',
    read: 'Gelesen',
    readyToMove: 'umzugsbereit',
    readyToMoveUC: 'Umzugsbereite Talente anzeigen',
    refineSearch: 'Suche verfeinern',
    releaseNotesHeading:
        'Hier finden Sie eine Übersicht über Änderungen, Verbesserungen' +
        ' und Problembehebungen.',
    relevance: 'Relevanz',
    removeTalent: 'Talent entfernen',
    removeTalentFromWatchlist: 'Talent aus Projekt entfernen',
    requestProduct: 'Produktanfrage talent.cloud',
    resume: 'Lebenslauf',
    salaryFrom: 'Gehalt ab',
    salaryRange: 'Gehaltsspanne',
    salaryRangeGrossAndNet: 'Gehaltsspanne (Brutto/Monat)',
    salaryTo: 'Gehalt bis',
    salutationMr: 'Herr',
    salutationMs: 'Frau',
    saveNewTemplate: 'Neue Vorlage speichern',
    saveRating: 'Bewertung speichern',
    saveSearch: 'Suche speichern',
    saveSearchParameters:
        'Die Suche wird mit allen angegebenen Parametern gespeichert.',
    search: 'Suche',
    searchResult: 'Suchergebnis',
    searchTips: 'Tipps zur Suchen mit boolschen Befehlen',
    selectAll: 'Alle auswählen',
    selectContact: 'Kontakt auswählen',
    send: 'Senden',
    sendRequest: 'Anfrage senden',
    settings: 'Einstellungen',
    shareNote: 'Notiz freigeben',
    shareTalent: 'Talent teilen',
    showAll: 'Alle anzeigen',
    showAllTalents: 'Alle Talente anzeigen',
    showMore: 'weitere',
    since: 'seit',
    skills: 'Kenntnisse',
    sort: 'Sortieren',
    staffingDemandBetween10And50: '10 - 50 Anstellungen',
    staffingDemandLower10: '< 10 Anstellungen',
    staffingDemandOver50: '> 50 Anstellungen',
    staffingNeeds: 'Ihr jährlicher Personalbedarf (optional)',
    subject: 'Betreff',
    sureAboutDeletingNote:
        'Sind Sie sicher, dass Sie die Notiz endgültig löschen möchten?',
    talent: 'Talent',
    talents: 'Talente',
    template: 'Vorlage',
    templates: 'Vorlagen',
    timeAgoOnline: 'vor $timeNumber $timeUnit online',
    title: 'Titel',
    today: 'heute',
    tryReload: 'Versuchen Sie die Seite neu zu laden',
    unanswered: 'Unbeantwortet',
    unread: 'Ungelesen',
    until: 'bis',
    week: 'Woche',
    weeks: 'Wochen',
    willingnessToTravel: 'Reisebereitschaft (Tage pro Woche)',
    willingnessToTravelTitle: 'Reisebereitschaft',
    withinLastMonth: 'Innerhalb des letzten Monats',
    withinLastXDays: 'Innerhalb der letzten $days Tage',
    withinLastXMonths: 'Innerhalb der letzten $months Monate',
    xFreeContactRequests: '$contingent freie Kontaktanfragen',
    year: 'Jahr',
    yearLC: 'Jahr',
    years: 'Jahre',
    yearsLC: 'Jahren',
    yesterday: 'gestern',
    youCanDoTheFollowing: 'Sie können folgendes tun:',
    youHave: 'Sie haben',
};
