export const notification = {
    addedTalentAndCreatedProject: 'Projekt erstellt und Talent hinzugefügt.',
    addedTalentToProject: 'Talent zum Projekt hinzugefügt.',
    addedTalentsAndCreatedProject: 'Projekt erstellt und Talente hinzugefügt.',
    addedTalentsToProject: 'Talente zum Projekt hinzugefügt.',
    addingTalentWatchlistFailed:
        'Talent konnte nicht zum Projekt hinzugefügt werden. Bitte versuchen Sie es erneut.',
    addingTalentsWatchlistFailed:
        'Talente konnten nicht zum Projekt hinzugefügt werden. Bitte versuchen Sie es erneut.',
    attachementsFailed:
        'Leider können wir die Anhänge momentan nicht laden. Wir kümmern uns bereits darum.',
    conversationSendRequests: 'Ihre Anfragen werden versendet!',
    conversationSendRequestsFailed:
        'An folgende Talente $failedTalentIds konnte keine Anfrage gesendet werden!',
    conversationSendRequestsInfo:
        'Wir benachrichtigen Sie, sobald eine Anfrage beantwortet wird.',
    createTemplateFailed:
        'Die Vorlage konnte nicht erstellt werden. Bitte versuchen Sie es erneut.',
    deleteMessage:
        'Die gewählte Nachricht wird endgültig in den Papierkorb verschoben.',
    deleteMessageFailed:
        'Die Nachricht konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
    deleteMessageTitle: 'Nachricht endgültig löschen',
    deleteMessages:
        'Die gewählten Nachrichten werden endgültig in den Papierkorb verschoben.',
    deleteMessagesTitle: 'Nachrichten endgültig löschen',
    deleteNoteFailed:
        'Notiz konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
    deleteProjectFailed:
        'Projekt konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
    deleteSavedSearchFailed:
        'Die Suche konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
    deleteTemplateFailed:
        'Die Vorlage konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
    deleteTemplateTitle: 'Die Vorlage wird unwiderruflich gelöscht.',
    deleteTemplatesFailed:
        'Die Vorlagen konnten nicht gelöscht werden. Bitte versuchen Sie es erneut.',
    deleteTemplatesTitle: 'Die Vorlagen werden unwiderruflich gelöscht.',
    deletedNote: 'Notiz gelöscht.',
    deletedTalentMessages:
        'Nachrichten von gelöschten Talenten können leider nicht angezeigt werden.',
    downloadFailed: 'Download fehlgeschlagen, bitte versuchen Sie es erneut',
    editNoteFailed:
        'Notiz konnte nicht bearbeitet werden. Bitte versuchen Sie es erneut.',
    editProjectFailed:
        'Projekt konnte nicht editiert werden. Bitte versuchen Sie es erneut.',
    editTemplateFailed:
        'Vorlage konnte nicht editiert werden. Bitte versuchen Sie es erneut.',
    editedNote: 'Notiz bearbeitet.',
    failedSendingMessageLabel: 'Es tut uns leid, etwas ist schief gegangen!',
    linkCopied: 'Link kopiert.',
    loadingMoreFilesFailed:
        'Leider konnten wir $numberOfFiles Dateien nicht laden. ' +
        'Wir arbeiten bereits an einer Lösung.',
    loadingOneFileFailed:
        'Leider konnten wir eine Datei nicht laden. Wir arbeiten bereits an einer Lösung.',
    loadingProjectsFailed:
        'Projektliste konnte nicht geladen werden, bitte laden Sie die Seite neu.',
    markConversationAsReadFailed:
        'Die Nachricht konnte nicht als gelesen markiert werden. Bitte versuchen Sie es erneut.',
    markConversationsAsReadFailed:
        'Die Nachrichten konnten nicht als gelesen markiert werden. Bitte versuchen Sie es erneut.',
    matchingBuyJobAdInfo:
        'Sie können neue Inserate im Online Shop kaufen, oder eines Ihrer bestehenden Inserate aktivieren um ein Matching zu ermöglichen',
    matchingNoMatchingAvailable: 'Kein Matching verfügbar',
    matchingNoMatchingFound:
        'Auf Basis Ihrer Stelleninserate werden aktuelle Profile qualifizierter Fachkräfte aus unserer Talentedatenbank angezeigt.\n',
    maxNumberOfProjectsReached: 'Maximale Anzahl von Projekten erreicht.',
    noAttachment: 'Es existiert kein Anhang',
    noMatchingJobAd: 'Es gibt kein Inserat, das auf Ihre Suchanfrage passt.',
    noResultTalentDetailPage: 'Es wurde kein Talent gefunden.',
    noResultViewContactList: 'Es wurde keine Kontaktanfrage gefunden.',
    noResultViewHeader: 'Wir konnten',
    noResultViewHeaderCandidate: 'keine Talente',
    noResultViewHeaderSearchResult: 'für Ihre Suchanfrage finden.',
    noResultViewHintFilter: 'Verwenden Sie weniger Filter',
    noResultViewHintProfile:
        'Das Talente-Profil mit der gesuchten Profil-ID ist nicht mehr sichtbar geschalten',
    noResultViewHintSearchResult: 'Verwenden Sie allgemeinere Suchbegriffe',
    noResultViewHintSemantic: 'Überprüfen Sie die Schreibweise',
    noResultViewListHeader: 'Folgende Tipps könnten für Sie hilfreich sein:',
    notesAndRatingsWillBeDeleted:
        'Bewertungen einzelner Talente werden endgültig gelöscht.',
    profileNotFound: 'Profil wurde nicht gefunden',
    profileNotFoundOrDeleted:
        'Dieses Profil existiert nicht oder wurde vom Benutzer gelöscht.',
    projectCreated: 'Projekt erstellt.',
    projectNotExisting: 'Das Projekt, das Sie öffnen möchten, existiert nicht.',
    removeTalentFromWatchlistFailed:
        'Das Talent konnte nicht aus dem Projekt entfernt werden. Bitte versuchen Sie es erneut.',
    savedNote: 'Notiz gespeichert.',
    savingNoteFailed:
        'Notiz konnte nicht erstellt werden. Bitte versuchen Sie es erneut.',
    savingProjectFailed:
        'Projekt konnte nicht erstellt werden. Bitte versuchen Sie es erneut.',
    savingRatingFailed:
        'Die Bewertung konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.',
    savingSearchError: 'Fehler beim Speichern der Suche.',
    savingSearchSuccessful: 'Suche gespeichert.',
    sendRequest: 'Eine Nachricht versendet!',
    sendRequests: '$length Nachrichten versendet!',
    shareNoteIsNotPossible:
        'Eine Freigabe ist nicht mehr möglich, da ein freigegebener Recruiter keine aktive talent.cloud Lizenz besitzt.',
    somethingWentWrong: 'Es ist etwas schief gelaufen.',
    successfulSendingMessageLabel: 'Ihre Nachricht wurde versendet!',
    successfulSendingMessageSublabel:
        'Wir benachrichtigen Sie, sobald die Anfrage beantwortet wird.',
    successfulSendingRequestLabel: 'Ihre Anfrage wurde versendet!',
    sureToDeleteProject:
        'Sind Sie sicher, dass Sie das Projekt löschen möchten?',
    sureToRemoveTalentFromWatchlist:
        'Sind Sie sicher, dass Sie dieses Talent aus dem Projekt entfernen möchten?',
    talentWasAlreadySaved: 'Talent ist bereits im Projekt gespeichert.',
    templateDataLoadingFailed:
        'Die ausgewählte Vorlage konnte nicht geladen werden. Bitte versuchen Sie es erneut.',
    templateListLoadingFailed: 'Die Vorlagen konnten nicht geladen werden.',
    templateSavingFailed:
        'Vorlage konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.',
    templateWasSaved: 'Vorlage wurde gespeichert.',
    tourLoadingError:
        'Die Tour konnte nicht gestartet werden. Bitte laden Sie die Seite neu um es erneut zu versuchen.',
};
