export const searchTips = {
    accountants: 'Buchhaltungsfachkraft',
    accounting: 'Buchhaltung',
    assistantLC: 'assistenz',
    assistantUC: 'Assistenz',
    by: 'durch',
    coaching: 'Coaching',
    commonFeature: 'Gemeinsames Merkmal',
    eitherOr: 'Entweder, oder',
    excludeFeature: 'Merkmal ausschließen',
    groupTerms: 'Begriffe Gruppieren',
    or: 'oder',
    professional: 'Fachkraft',
    sale: 'Vertrieb',
    sales: 'Vertriebs',
    salesAssistance: 'Vertriebsassistenz',
    searchResultAnd:
        '...sucht nur Vertriebsassistenzen, die auch Buchhaltungsfachkräfte sind.',
    searchResultEnd:
        '...sucht nach Vertriebs-, Pflege-, Geschäftsführungsassistenzen etc.',
    searchResultGroup1:
        '...sucht Assistenzen und Hilfskräfte, die Microsoft Office und Outlook',
    searchResultGroup2:
        'beherrschen - aber keine, die in Vertrieb oder Buchhaltung tätig sind.',
    searchResultOr1:
        '...sucht nach Vertriebsassistenzen, Buchhaltungsfachkräften und',
    searchResultOr2:
        'Vertriebsassistenzen, die auch Buchhaltungsfachkräfte sind.',
    searchResultOrNot1:
        '...sucht Vertriebsassistenzen oder Buchhaltungsfachkräfte, aber keine Coaches.',
    searchResultOrNot2:
        'Auch nicht, wenn diese Vertriebsassistenzen oder Buchhaltungsfachkräfte sind.',
    searchResultSpecific: '...sucht ausschließlich nach Vertriebsassistenzen.',
    searchResultStart:
        '...sucht nach Vertriebsassistenzen, -partner, -manager etc.',
    separated: 'getrennt ',
    specificTerm: 'Bestimmter Begriff',
    startOrEndOfWord: 'Wortanfang oder -ende',
};
