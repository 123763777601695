export const contract = {
    activate: 'Nutzung Talentdatenbank freischalten',
    buy20ContactPackage: '20er Kontaktpaket kaufen',
    contactMessage: '1 Nachricht',
    contactMessages: '$length Nachrichten',
    contactRequest: '1 Anfrage',
    contactRequestFirst: '1 Erstkontakt',
    contactRequests: '$length Anfragen',
    contactRequestsAndMessages: '$requestType, $messageType',
    contactRequestsFirst: '$length Erstkontakte',
    contactTalent: '1 Talent kontaktieren',
    contactTalents: '$length Talente kontaktieren',
    contractsNotAgreedInfo:
        'Die Talentedatenbank (talent.cloud) ist derzeit gesperrt. Bitte nehmen Sie zur Kenntnis, dass für dieses Produkt ein ergänzender Vertrag aufgrund der DSGVO (Datenschutzgrundverordnung) notwendig ist.',
    deductedContactCredit: 'Ihr Kontingent wird um $request reduziert.',
    hasGDPRConsentInfo:
        'Bitte beachten Sie, dass eine Freischaltung für die Nutzung der Talentdatenbank erst nach tatsächlichem Vertragsabschluss erfolgen kann.',
    locked: 'Nutzung Talentedatenbank (talent.cloud) gesperrt',
    requestNow: 'Jetzt anfragen',
    requestNowInfo:
        'Sie können uns entweder eine Anfrage schicken oder ein 20er Kontaktpaket direkt über den Online Shop erwerben.',
    requestNowInfoWithoutOnlineShop: 'Sie können uns eine Anfrage schicken.',
    reset: 'Sobald Sie dies bestätigt haben, bekommen Sie Zutritt zu Ihrer talent.cloud Testumgebung. Eine Freischaltung kann erst nach tatsächlichem Vertragsschluss erfolgen.',
    showContract: 'Verträge ansehen',
};
