export const contract = {
    activate: 'Activate use of talent database',
    buy20ContactPackage: 'Buy 20 contact pack',
    contactMessage: 'one message',
    contactMessages: '$length messages',
    contactRequest: 'one request',
    contactRequests: '$length requests',
    contactRequestsAndMessages: '$requestType, $messageType',
    contactTalent: 'Contact 1 talent',
    contactTalents: 'Contact $length talents',
    contractsNotAgreedInfo:
        'The talent database (talent.cloud) is currently blocked. Please note that a supplementary contract is required for this product due to the GDPR (General Data Protection Regulation)',
    deductedContactCredit: 'Your contingent will be reduced by $request.',
    hasGDPRConsentInfo:
        'Please note that activation for the use of the talent database can only take place after the actual conclusion of the contract.',
    locked: 'Use of talent database (talent.cloud) blocked',
    requestNow: 'Request now',
    requestNowInfo:
        'You can either send us an request or purchase a 20-pack of contacts directly via the online shop.',
    requestNowInfoWithoutOnlineShop: 'You can send us a request.',
    reset: 'As soon as you have confirmed this, you will be given access to your talent.cloud test environment. Activation can only take place after the contract has actually been concluded.',
    showContract: 'View contracts',
};
