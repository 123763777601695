export const page = {
    infoPage: 'Information',
    matching: 'Matching',
    messages: 'Messages',
    platform: 'karriere.at Business Portal',
    projects: 'Projects',
    releases: 'Releases',
    savedSearch: 'Saved Search',
    search: 'Search',
};
