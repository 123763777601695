export const searchTips = {
    accountants: 'accountants',
    accounting: 'Accounting',
    assistantLC: 'assistant',
    assistantUC: 'Assistant',
    by: 'by',
    coaching: 'coaching',
    commonFeature: 'Common feature',
    eitherOr: 'Either, or',
    excludeFeature: 'Exclude feature',
    groupTerms: 'Group terms',
    or: 'or',
    professional: 'Professional',
    sale: 'Sale',
    sales: 'Sales',
    salesAssistance: 'Sales assistance',
    searchResultAnd:
        '...searches only for sales assistants who are also accountants.',
    searchResultEnd:
        '...searches for sales-, nursing-, management assistants etc.',
    searchResultGroup1:
        '...searches for assistants and auxiliaries who are proficient',
    searchResultGroup2:
        'in Microsoft Office and Outlook - but do not work in sales or accounting.',
    searchResultOr1:
        '...searches for sales assistants, accounting specialists and',
    searchResultOr2: 'sales assistants who are also accountants.',
    searchResultOrNot1:
        '...searches for sales assistants or accountants, but not coaches.',
    searchResultOrNot2: 'Not even if they are sales assistants or accountants.',
    searchResultSpecific: '...searches for sales assistants.',
    searchResultStart:
        '...searches for sales assistants, -partners, -managers etc.',
    separated: 'separated ',
    specificTerm: 'Specific term',
    startOrEndOfWord: 'Start or end of word',
};
