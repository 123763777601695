export const tooltips = {
    activeOnJobSearch:
        'Dieses Talent ist auf der karriere.at Plattform \n aktiver als 50% der sichtbaren Talente.',
    applicantDeleted: 'Dieses Profil wurde gelöscht.',
    applicantInactive:
        'Dieses Profil ist momentan deaktiviert. Das Talent kann die Sichtbarkeit jederzeit ändern.',
    approvalStatusAccepted: 'Das Talent hat Ihre Anfrage angenommen.',
    approvalStatusPending:
        'Das Talent hat noch nicht auf Ihre Anfrage reagiert.',
    approvalStatusUnapproved:
        'Das Talent wurde noch nicht von Ihnen kontaktiert.',
    availableWithValidLicense:
        'Dieses Funktionalität ist mit einer aktiven Lizenz verfügbar.',
    deletedTalent:
        'Das Talent hat sein Profil gelöscht und kann nicht mehr kontaktiert werden.',
    inactiveTalent:
        'Das Talent hat seine Sichtbarkeitseinstellungen geändert und kann nicht mehr kontaktiert werden.',
    lastContacted: 'Zuletzt kontaktiert am:',
    lastContactedFrom:
        'Zuletzt am $lastMessageDate kontaktiert von $contactedBy',
    lastVisitedOn: 'Zuletzt angesehen am $visitedOn',
    projectAdmin:
        'Nur ein Projekt-Administrator kann ein Projekt endgültig löschen.',
    saveAsTemplate: 'Als Vorlage speichern',
};
