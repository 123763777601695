export const input = {
    addAttachment: 'Anhang hinzufügen',
    contactListFilterPlaceholder: 'Talent-Name, Job-Titel, …',
    contactRequest: 'Anfrage zur Kontaktdatenfreigabe',
    educationFilter: 'Ausbildung wählen',
    email: 'E-Mail Adresse',
    employmentFilter: 'Anstellungsart wählen',
    enterEmail: 'E-Mail Adresse eingeben',
    enterProjectName: 'Projektname eingeben',
    enterSubject: 'Betreff eingeben',
    firstName: 'Vorname',
    initialSearchPlaceholder: 'Finden Sie die beste Besetzung',
    jobFieldFilter: 'Berufsfeld wählen',
    keywordFilter: 'Bereich im Lebenslauf wählen',
    keywordPlaceholder: 'Jobtitel, Profil-ID, “exakter Wortlaut”, …',
    languageFilter: 'Sprache wählen',
    lastName: 'Nachname',
    levelFilter: 'alle',
    locationPlaceholder: 'Ort, Region',
    matchingSearchPlaceholder: 'Inserate durchsuchen',
    maximum: 'maximal $amount',
    messageBody: 'Ihre Nachricht',
    messageBodyOptional: 'Ihre Nachricht (optional)',
    moveMessage: 'Nachricht wurde in den Papierkorb verschoben.',
    moveMessages: 'Nachrichten wurden in den Papierkorb verschoben.',
    notSpecified: 'Nicht angegeben',
    phoneNumber: 'Telefonnummer',
    pleaseSelect: 'Bitte wählen...',
    requestMessage:
        'Guten Tag!\n\nWir suchen aktuell nach [Positionsbezeichnung] und ' +
        'Ihr Profil auf karriere.at hat unser Interesse geweckt! Wir würden uns freuen, Sie näher kennen ' +
        'zu lernen und würden Sie gerne direkt kontaktieren. Wenn Sie uns Ihre Kontaktdaten ' +
        'freischalten, melden wir uns in Kürze persönlich mit weiteren Informationen!\n\nFreundliche Grüße,',
    salaryTo: 'z.B.: 3.500',
    salutation: 'Anrede',
    searchIn: 'Suchen in',
    searchName: 'Name der Suche eingeben',
    selectStaffingNeeds: 'Jährlichen Personalbedarf wählen',
    show: 'Anzeigen: ',
    subject: 'Betreff',
    templateBody: 'Inhalt der Vorlage',
    templateName: 'Name der Vorlage',
    templateNameExample: 'z.B. "Anschreiben Buchhaltungsfachkraft"',
    templateSelect: 'Vorlage wählen',
    templateSelectEmpty: 'Keine Vorlagen vorhanden',
    templateSubject: 'Betreff der Vorlage',
    titleOptional: 'Titel (optional)',
    writeMessage: 'Nachricht schreiben…',
};
