export const button = {
    add: 'Add',
    addAllToProject: 'Add all to project',
    addExperience: '+ Add job experience',
    addLanguage: '+ Add language',
    apply: 'Apply',
    back: 'Back',
    backToOverview: 'Back to overview',
    backToProjects: 'Back to Projects',
    backToSearch: 'Back to search',
    buyContactPackage: 'Buy contact package',
    buyContactPackageNow: 'Buy contact package now',
    cancel: 'Cancel',
    close: 'Close',
    contactMultiTalents: 'Contact $length talents',
    contactOneTalent: 'Contact 1 talent',
    contactTalents: 'Contact talents',
    copyLink: 'Copy link',
    createProject: 'Create Project',
    deleteMessage: 'Delete message',
    deleteMessages: 'Delete messages',
    deleteSavedSearch: 'Delete savedSearch',
    deleteTemplate: 'Delete template',
    deleteTemplates: 'Delete templates',
    downloadAllAttachments: 'Download all attachments',
    edit: 'Edit',
    filterReset: 'Reset filter',
    filterSearch: 'Search with filters',
    filterSearchShort: 'Search',
    giveRating: 'Give rating',
    hideDetails: 'hide details',
    less: 'less',
    linkHidden: 'link hidden',
    login: 'Sign in',
    logout: 'sign out',
    more: 'more',
    moreOptions: 'More options',
    newNote: 'New note',
    newTemplate: 'New template',
    next: 'Next',
    printAsPdf: 'Print profile as PDF',
    save: 'Save',
    saveChanges: 'Save changes',
    saveNote: 'Save note',
    saveSearch: 'Save search',
    saveSettings: 'Save settings',
    saveTemplate: 'Save template',
    search: 'Search',
    send: 'Send',
    sendContactRequest: 'Send request',
    sendMessage: 'Send message',
    sendProductRequest: 'Send product request',
    sendRequest: 'Send request',
    shareProfile: 'Share profile',
    show: 'Show',
    showAll: 'show all',
    showDetails: 'show details',
    showLess: 'show less',
    showMoreJobAds: 'Show more job ads',
    showMoreMessages: 'Show more messages',
    showMoreNotes: 'Show more notes',
    showMoreSavedSearch: 'Show more saved search',
    showMoreTalents: 'Show more talents',
    showMoreTemplates: 'Show more templates',
    showProfile: 'Show profile',
    toJobAdOverview: 'To the job ads overview',
    toOnlineShop: 'To the online shop',
    toSearch: 'To talent search',
    understood: 'Understood',
    upload: 'upload',
};
